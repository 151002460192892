@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* FONTS */
  @font-face {
    font-family: 'Rubik';
    src: url('assets/fonts/rubik/Rubik-Regular.ttf');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Rubik';
    src: url('assets/fonts/rubik/Rubik-Light.ttf');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Rubik';
    src: url('assets/fonts/rubik/Rubik-Medium.ttf');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Rubik';
    src: url('assets/fonts/rubik/Rubik-Bold.ttf');
    font-weight: 700;
  }
}

:root {
  --toastify-font-family: Rubik, serif;
  --toastify-text-color-light: #242423;
  --toastify-color-info: #3b82f6;
  --toastify-color-success: #22c55e;
  --toastify-color-error: #ef4444;
  --toastify-color-progress-light: #ff9821;
}

html {
  @apply text-black text-base font-normal;
  font-family: Rubik, serif;
}

h1 {
  @apply text-4xl md:text-5xl font-bold;
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-2xl font-bold;
}

h4 {
  @apply text-lg;
}

h1 + p,
h2 + p,
h3 + p,
h4 + p {
  @apply mt-1.5;
}

p {
  @apply text-base;
}

a {
  @apply no-underline hover:text-orange transition-colors ease-in-out;
}

a svg {
  @apply transition-colors ease-in-out;
}

svg.icon {
  @apply w-6 h-6;
}

b,
strong {
  @apply font-bold;
}

ul,
ol {
  @apply list-outside ml-12 my-4;
}

ul {
  @apply list-disc;
}

ul ul {
  @apply list-[circle] mb-0;
}

ol {
  @apply list-decimal;
}

ul li + li,
ol li + li {
  @apply mt-4;
}

.table-of-contents ol {
  @apply list-none;
  counter-reset: item;
}

.table-of-contents ol ol {
  @apply my-2;
}

.table-of-contents ol li + li {
  @apply mt-2;
}

.table-of-contents ol > li {
  counter-increment: item;
}

.table-of-contents ol > li:before {
  @apply mr-0.5;
}

.table-of-contents ol > li:before {
  content: counters(item, '.') '. ';
}

.table-of-contents ol ol > li:before {
  content: counters(item, '.') ' ';
}

.form label {
  @apply font-semibold;
}

.form label.checkbox,
.form label.radio {
  @apply font-normal cursor-pointer;
}

.form select,
.form input:not([type='checkbox']):not([type='radio']) {
  @apply appearance-none w-full px-4 py-2 mt-2 font-normal border border-grey-dark focus:outline-none rounded-xl;
}

.form select {
  @apply pr-12 cursor-pointer;
}

.form input:not([type='checkbox']):not([type='radio']).error {
  @apply border-red-500;
}

.form input[type='checkbox'] {
  @apply appearance-none relative w-4 h-4 my-0 ml-0 mr-2 translate-y-0.5 rounded border border-grey-dark bg-white transition-colors ease-in-out;
}

.form input[type='checkbox']::before {
  @apply absolute top-0 left-1/2 -translate-x-1/2 inline-block w-1.5 h-3 rotate-[35deg] border-b-2 border-r-2 border-white rounded-br-sm opacity-0 transition-opacity ease-in-out;
  content: '';
}

.form input[type='checkbox']:checked {
  @apply border-orange bg-orange;
}

.form input[type='checkbox']:checked::before {
  @apply opacity-100;
}

.form input[type='radio'] {
  @apply appearance-none relative w-4 h-4 my-0 ml-0 mr-2 translate-y-0.5 rounded-full border border-grey-dark bg-white transition-colors ease-in-out;
}

.form input[type='radio']::before {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block w-2.5 h-2.5 rounded-full bg-orange opacity-0 transition-opacity ease-in-out;
  content: '';
}

.form input[type='radio']:checked {
  @apply border-orange;
}

.form input[type='radio']:checked::before {
  @apply opacity-100;
}

/* FILE UPLOAD INPUT */
.form .input-file-wrapper {
  @apply flex mt-2;
}

.form .input-file-wrapper input:disabled {
  @apply truncate bg-white border-r-0 rounded-r-none mt-0 pr-10;
}

/* Browse button */
.form .input-file-wrapper label {
  @apply flex items-center justify-center px-4 py-3 font-normal text-white text-xs rounded-r-xl border border-orange-gradient-from border-b-orange-gradient-to bg-gradient-to-b from-orange-gradient-from to-orange-gradient-to cursor-pointer hover:from-transparent hover:to-transparent hover:border-orange hover:text-orange;
}

.article-content h2 + p,
.article-content h3 + p,
.article-content h4 + p,
.article-content h5 + p,
.article-content h6 + p,
.article-content p + h2,
.article-content p + h3,
.article-content p + h4,
.article-content p + h5,
.article-content p + h6,
.article-content p + p {
  @apply mt-4;
}

/* NOTIFICATION TOAST */
.Toastify__toast-container--top-right {
  @apply top-16 right-4;
}

.Toastify__toast {
  @apply rounded-md shadow-md;
}

/* Error messages */
p.error {
  @apply mt-2 font-normal text-red-500;
}

/* WYSIWYG Editor */
.rsw-editor {
  @apply !border-grey-dark !rounded-xl mt-2 pb-12;
}

.rsw-editor .rsw-toolbar {
  @apply bg-grey border-grey-dark;
}

.rsw-editor .rsw-toolbar .rsw-separator {
  @apply border-grey-dark;
}

.rsw-editor .rsw-toolbar .rsw-btn {
  @apply flex items-center justify-center;
}

.rsw-editor .rsw-toolbar .rsw-dd {
  @apply w-auto mt-0 pr-4 py-1 rounded-none bg-transparent border-none;
}

.rsw-editor .rsw-toolbar .rsw-dd option:last-child {
  @apply hidden;
}

.rsw-editor .rsw-ce {
  @apply min-h-[250px] max-h-[250px] overflow-auto p-4 focus:outline-none;
}
