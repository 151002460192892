.hero h2 {
  @apply text-5xl font-bold;
}

.hero h3 {
  @apply text-3xl font-normal;
}

.hero h3 + h2 {
  @apply mt-1;
}
