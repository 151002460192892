.button.button-small svg,
.button.button-small .spinner-wrapper {
  @apply lg:w-5 lg:h-5;
}

.button .spinner-wrapper {
  @apply w-6 h-6;
}

.button .spinner {
  @apply w-5 h-5;
}

.button.button-small .spinner {
  @apply lg:w-4 lg:h-4;
}

.button .spinner {
  @apply animate-spin flex border-2 border-l-transparent rounded-full;
}
