/* DESKTOP */
.sidebar-parent-link svg {
  @apply fill-black/50 mr-4 transition ease-in-out duration-300;
}

.sidebar-parent-link.is-active svg,
.sidebar-parent-link:hover svg {
  @apply fill-black;
}

/* MOBILE */
.sidebar-menu-button {
  @apply block h-1 w-8 bg-black rounded-full transition ease-in-out duration-300;
}

.sidebar-container {
  @apply fixed z-40 top-0 left-0 pt-20 w-72 max-w-full h-full overflow-y-auto bg-grey shadow-md ease-in-out duration-300;
}

.sub-menu-items-icon svg {
  @apply fill-black;
}
