.menu-bar-link .arrow-right {
  @apply fill-black transition translate-x-0;
}

.menu-bar-link:hover .arrow-right {
  @apply fill-orange translate-x-1.5;
}

.menu-bar-container {
  @apply absolute top-full left-0 z-40 w-full pt-1 transition-all ease-in-out duration-300;
}

.menu-bar-container-inner {
  @apply w-full max-h-80 overflow-y-auto bg-white border border-grey-light p-5 lg:px-10 lg:py-6 rounded-xl shadow-lg;
}

.menu-bar-mobile-container {
  @apply fixed inset-0 overflow-y-auto z-40 bg-white border-t border-grey px-5 py-10 transition ease-in-out duration-300;
}
