.block-outer {
  @apply relative py-5 lg:py-12;
}

.two_columns .block-outer,
.three_columns .block-outer,
.block-outer + .block-outer:not(.last-block) {
  @apply lg:py-7;
}

.two_columns,
.three_columns {
  @apply !py-0;
}

.two_columns > .block-inner,
.three_columns > .block-inner {
  @apply px-0;
}

.block-inner {
  @apply relative px-5 lg:px-12;
}
